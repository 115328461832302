import QRCode from 'qrcode.react';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import shield from '../assets/images/shield.svg';
import Button from '../ui/widgets/Button/Button';
import Field from '../ui/widgets/Field/Field';
import {
  getCardNumberMask,
  getCurrencySymbol,
  getMerchantLogo,
} from '../services/utils';
import { Transaction } from '../services/types';
import card from '../assets/images/card.svg';
import { renderImage } from '../utils/renderImage';

const Container = styled.div`
  max-width: 500px;
  min-width: 270px;
  max-height: auto;
  background-color: ${({ theme }) => theme.background};
  margin: 0 20px 30px 20px;
  padding: 14px;
  border-radius: var(--border-radius);
  box-shadow: 0px 0px 4px 0px #00000040;
`;

const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
`;

const QRCodeDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: ${({ theme }) => theme.inputBackground};
  border-radius: var(--border-radius);
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 10px;
`;

const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

interface SmallFieldGroupProps {
  direction: 'row' | 'column';
}

const directions = {
  row: css`
    display: flex;
    justify-content: space-between;
    gap: 15px;
  `,
  column: css`
    display: flex;
    flex-direction: column;
    gap: 15px;
  `,
};

const SmallFieldGroup = styled.div<SmallFieldGroupProps>`
  ${(props) => directions[props.direction!]};
  width: ${(props) => (props.direction === 'column' ? '113px' : 'auto')};
`;

SmallFieldGroup.defaultProps = {
  direction: 'row',
};

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background-color: var(--color-blue);
  color: var(--color-white);
  padding: 16px;
`;

const HeaderGroup = styled.div`
  width: 250px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
  }
`;

const Labeled = styled.div`
  display: flex;
  color: ${({ theme }) => theme.labelColor};
  flex-direction: column;
  gap: 7px;
`;

const Timer = styled.p`
  font-size: 20px;
  color: var(--color-white);
  font-weight: 500;
`;

const P = styled.p`
  font-size: 9px;
  font-weight: 300;
`;

interface P2PFormProps {
  transaction: Transaction;
  timeLeft: string;
  ticketId: string;
  onCancel?: () => void;
  onConfirm?: () => void;
}

const P2PForm: FC<P2PFormProps> = ({
  transaction,
  ticketId,
  onCancel,
  onConfirm,
  timeLeft,
}) => {
  const { requisite, isRedirectUrlExist, amount } = transaction;

  const currencySymbol = getCurrencySymbol(requisite.currency);

  const requesiteTitle = (type: string | undefined) => {
    switch (type) {
      case 'bank_account':
        return 'Номер счета';
      case 'bank_card':
        return 'Номер карты';
      case 'crypto_wallet':
        return 'Номер кошелька';
      case 'phone_number':
        return 'Номер телефона';
      default:
        return 'Номер реквизита';
    }
  };

  return (
    <>
      <Header>
        <HeaderGroup>
          <Title>
            <img src={shield} alt='shield' />
            <h3>Пополнение</h3>
          </Title>
          {!isRedirectUrlExist && <Timer>{timeLeft}</Timer>}
        </HeaderGroup>
        <P>ID транзакции: {ticketId}</P>
      </Header>
      <Container>
        <ImgWrapper>
          {getMerchantLogo(transaction.merchant) ? (
            <img
              width={85}
              src={getMerchantLogo(transaction.merchant)} //!transaction.merchant
              alt='лого мерчанта'
            />
          ) : (
            <h3>{transaction.merchant.toLocaleUpperCase()}</h3>
          )}
        </ImgWrapper>
        <FieldGroup>
          <Field image={card} text={'По номеру карты'} />

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <SmallFieldGroup
              direction={requisite.qrCodeData ? 'column' : 'row'}
            >
              {amount && (
                <Labeled>
                  <span>Сумма перевода</span>
                  <Field text={amount} copy currency={currencySymbol} />
                </Labeled>
              )}

              <Labeled>
                <span>Банк</span>
                {renderImage(requisite.acquiringBank) ? (
                  <Field
                    width={amount ? '113px' : '254px'}
                    image={renderImage(requisite.acquiringBank)}
                  />
                ) : (
                  <Field
                    width={amount ? '113px' : '254px'}
                    text={requisite.acquiringBank?.toUpperCase()}
                  />
                )}
              </Labeled>
            </SmallFieldGroup>
            {requisite.qrCodeData && (
              <Labeled>
                <p>QR-код</p>
                <QRCodeDiv>
                  <QRCode size={100} value={requisite.qrCodeData} />
                </QRCodeDiv>
              </Labeled>
            )}
          </div>
          {!isRedirectUrlExist && (
            <Labeled>
              <span>{requesiteTitle(transaction.requisite.type)}</span>
              <Field
                copy
                copyType='clean'
                text={getCardNumberMask(transaction.requisite.cardNumber)}
              />
            </Labeled>
          )}
          <Labeled>
            <span>Получатель</span>
            <Field text={transaction.requisite.name} />
          </Labeled>
          <ButtonGroup>
            <Button variant='secondary' onClick={onCancel}>
              Отмена
            </Button>
            <Button onClick={onConfirm} variant='primary'>
              {isRedirectUrlExist ? 'Перейти к оплате' : 'Оплатил'}
            </Button>
          </ButtonGroup>
        </FieldGroup>
      </Container>
    </>
  );
};

export default P2PForm;
