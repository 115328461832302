import { FC, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import copyImage from '../../../assets/images/copy.svg';
import success from '../../../assets/images/success.svg';

const StyledField = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: ${({ theme }) => theme.inputBackground};
  border-radius: var(--border-radius);
  padding: 10px;
  outline: 1px solid ${({ theme }) => theme.inputBorder};
  border: none;
  min-width: fit-content;
  min-height: 40px;
`;

const positions = {
  center: css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,
  start: css`
    position: relative;
  `,
};

interface StyledSpanProps {
  position?: 'center' | 'start';
  fontSize: string;
}

const StyledSpan = styled.span<StyledSpanProps>`
  color: ${({ theme }) => theme.text};
  word-break: break-all;
  font-size: ${(props) => props.fontSize};
  ${(props) => positions[props.position!]}
`;

StyledSpan.defaultProps = {
  position: 'start',
  fontSize: '14px',
};

interface FieldProps {
  image?: string;
  text?: string | number;
  copy?: boolean;
  copyType?: 'clean' | 'full' | 'phone';
  currency?: string;
  width?: string;
  position?: 'center' | 'start';
  fontSize?: string;
  borderColor?: string;
  style?: object;
}

const Field: FC<FieldProps> = ({
  image,
  text,
  copy,
  currency,
  width,
  position,
  fontSize,
  borderColor,
  style,
  copyType = 'full',
}) => {
  const [copied, setCopied] = useState(false);
  const textRef = useRef<HTMLSpanElement | null>(null);

  const handleCopy = () => {
    if (textRef.current && textRef.current.innerText) {
      let clipboardData;

      switch (copyType) {
        case 'clean':
          clipboardData = textRef.current.innerText.replace(/\D/gi, '');
          break;
        case 'full':
          clipboardData = textRef.current.innerText;
          break;
        case 'phone':
          clipboardData = `+${textRef.current.innerText.replace(/\D/gi, '')}`;
          break;
      }

      navigator.clipboard
        .writeText(clipboardData)
        .then(() => {
          setCopied(true);

          setTimeout(() => {
            setCopied(false);
          }, 1500);
        })
        .catch((err) => {
          console.error('Failed to copy text: ', err);
        });
    }
  };

  return (
    <StyledField
      style={{
        width: width ? width : '',
        justifyContent: copy ? 'space-between' : '',
        position: 'relative',
        outlineColor: borderColor || '',
      }}
    >
      {image && (
        <img src={image} alt={text?.toString()} height={18} width={'auto'} />
      )}
      {text !== null || text !== undefined ? (
        <StyledSpan
          ref={textRef}
          position={position || 'start'}
          fontSize={fontSize ? fontSize : '14px'}
          style={style}
        >
          {currency ? text + ' ' + currency : text}
        </StyledSpan>
      ) : null}
      {copy && !copied && (
        <img
          style={{ objectFit: 'cover' }}
          src={copyImage}
          alt='copy'
          onClick={handleCopy}
          width={18}
          height={18}
        />
      )}
      {copied && <img src={success} alt='success' width={19} height={19} />}
    </StyledField>
  );
};

export default Field;
